.fundoLogin {
    /* Estilização da tela de login */
    min-height: 100vh;
    background-color: #e9edf2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.label {
    /* Estilização das labels do formulario de login - estilização para compoenent especifico */
    font-weight: bold;
}

label {
    /* Estilização das labels de todo sistema - estilização para components gerais */
    font-weight: bold;
}

.overlay {
    /* Fixação do modal no meio da tela */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1008;
}

.estilizacaoModais {
    /* Estilização de dentro do modal */
    background-color: rgb(255, 255, 255);
    position: absolute;
    padding: 20px;
    top: 30%;
    left: 30%;
    right: 30%;
}

.margemTop {
    /* Margem superior para os componentes */
    margin-top: 5%;
}

.tamanhoTextarea {
    /* Tira a responsividade da textarea */
    resize: none;
}

.margemBottom {
    /* Margem inferior para os componentes */
    margin-bottom: 1%;
}

.modalErro {
    /* Adiciona um espaço ao componente */
    white-space: pre-wrap;
}

/* Estilização dos titulos das páginas */

.navbar-brand {
    margin-right: 0 !important;
}

@media (max-width: 320px) {
    .navbar-brand {
        width: 50% !important;
    }
}

@media (min-width: 375px) and (max-width: 425px) {
    .navbar-brand {
        width: 60% !important;
    }
}

@media (min-width: 768px) {
    .navbar-brand {
        width: 40% !important;
    }
}

@media (min-width: 1024px) {
    .navbar-brand {
        width: 30% !important;
    }
}

@media (min-width: 1044px) {
    .navbar-brand {
        width: 20% !important;
    }
}

/*  */

.margemNavlink {
    /* Especifica a margem para o alinhamento dos navLink da navbar */
    margin-left: 15px
}

.tiraMarcador {
    /* Tira marcador da lista do icone sair */
    list-style-type: none
}

.cordeFundo {
    /* background-color: black; */
    background-color: #F5F5F5;
}

.expansaoDiv {
    /* Faz com que as divs de conteudo aumentem para que a cor de fundo seja aplicada para a tela toda */
    min-block-size: -webkit-fill-available;
}

.centralizaPermissoes {
    /* Faz com que os selects da tela de Permissões fiquem centralizados */
    justify-content: center;
}

/* Estilizações do footer */

@media (max-width: 768px) {
    .footer-position {
        position: relative;
    }
}

@media (min-width: 768px) {
    .footer-position {
        position: absolute;
    }
}

.footersite {
    height: auto;
    width: 100%;
}

.color-footer {
    color: white;
}

.tamanhofooter {
    min-height: 16.3vh;
}

.paddingBottom {
    padding-bottom: 0.4%;
}

.tamanhoFonte {
    font-size: medium;
}

/*  */

.cardLogin {
    /* Estilização do card de login */
    margin: 2%;
    border: 1px solid #AAA !important;
}

.logo {
    /* Define a margem da parte superior do logo da tela de login */
    margin: 2%;
}

.labelCheckbox {
    /* Faz o alinhamento da label do checkbox da tela de relatorio */
    margin-right: 5%;
    margin-bottom: 0;
    vertical-align: -webkit-baseline-middle;
}

/* Estilização do modal de escolha de colaborador para baixar PDF - Tela de Marcações */
.modal-pdf {
    background-color: rgb(255, 255, 255);
    position: absolute;
    padding: 20px;
    top: 30%;
    justify-content: center;
    left: 10%;
    height: auto;
}
@media (min-width: 768px) {
    .modal-pdf {
        left: 20%;
    }
}

.card-header {
    /* Estiliza o card-header do modal de detalhamento da tela de marcações*/
    padding: 0 !important
}

.tiraEstiloLink, .tiraEstiloLink:link, .tiraEstiloLink:visited, .tiraEstiloLink:hover, .tiraEstiloLink:active {
    /* Tira a estilização da tag a */
    color: inherit;
}

.alinhamentoCheck {
    padding-top: 5%;
    padding-left: 10px;
    margin-bottom: 0px;
}